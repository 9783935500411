import { Button, Image } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = eventData => {
  const { handEdite, handRemove, handContent, disabled } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '分类',
      dataIndex: 'clasz',
      key: 'clasz',
      ellipsis: true,
      render: row => {
        return row?.name
      },
    },
    {
      title: '权益类型',
      dataIndex: 'benefitType',
      key: 'benefitType',
      ellipsis: true,
      render: row => {
        return row?.name
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      render: icon => {
        return icon ? <Image src={icon} width={24} /> : ''
      },
    },
    {
      title: '价值',
      dataIndex: 'price',
      key: 'price',
      ellipsis: true,
    },
    {
      title: '说明',
      dataIndex: 'descr',
      key: 'descr',
      width: 200,
      ellipsis: true,
    },
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'changge',
      fixed: 'right',
      width: 200,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.equityEdite}
              type="link"
              disabled={disabled}
            >
              编辑
            </ButtonGroup>
            <ButtonGroup
              onClick={() => handContent(row)}
              btnId={btnApiList.equityContnet}
              type="link"
              disabled={disabled}
            >
              内容
            </ButtonGroup>
            <TipButton
              title="你确定要该权益吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.equityDelete}
            >
              <Button type="link" disabled={disabled}>
                删除
              </Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
const dataSource = [
  {
    key: '1',
    index: 1,
    id: 1,
  },
  {
    key: '2',
    index: 2,
    id: 2,
  },
]
export { columns, dataSource }
