import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { getMerchantTypeList } from 'reduxs/operation/merchantType'
import {
  addPayment,
  editePayment,
  paymentDetails,
} from 'reduxs/operation/payment'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'

const plainOptions = [
  { label: '站内支付', value: 1 },
  { label: '站外支付', value: 2 },
]
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [typeList, setTypeList] = useState([]) // 类型列表
  useEffect(() => {
    getDetails()
    getTypeList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { paymentMchId } = editeData
    const payModa = []
    if (paymentMchId) {
      const res = await paymentDetails(paymentMchId)
      if (res.result === 200) {
        const { data } = res
        if (data.within) {
          payModa.push(1)
        }
        if (data.outside) {
          payModa.push(2)
        }
        data.payMode = payModa
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 获取商户类型列表
  const getTypeList = async () => {
    const res = await getMerchantTypeList({ paging: false, status: 1 })
    if (res.result === 200) {
      const { data } = res
      setTypeList(data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { paymentMchId } = editeData
    const data = getSubData(values)
    const { payMode } = data
    data.sysn = undefined
    values.outside = 0
    values.within = 0
    if (payMode.includes(1)) {
      values.within = 1
    }
    if (payMode.includes(2)) {
      values.outside = 1
    }
    delete data.payMode
    if (paymentMchId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { paymentMchId } = editeData
    values.paymentMchId = paymentMchId
    const res = await editePayment(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改支付商户成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addPayment(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增支付商户成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={800}
      title={editeData.paymentMchId ? '编辑支付商户' : '新增支付商户'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="应用ID"
                name="appId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入应用ID',
                  },
                ]}
              >
                <Input placeholder="请输入应用ID（无，可输入商户ID）" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="类型"
                name="paymentMchTypeId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择类型',
                  },
                ]}
              >
                <Select placeholder="请选择类型">
                  {typeList.map((item, index) => (
                    <Option value={item.paymentMchTypeId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="商户ID"
                name="mchId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入ID',
                  },
                ]}
              >
                <Input placeholder="请输入ID" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="支付域名" name="payDomain">
                <Input placeholder="请输入支付域名" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="支付方式"
                name="payMode"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择支付方式',
                  },
                ]}
              >
                <Checkbox.Group options={plainOptions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="公钥" name="publicKey">
                <Input placeholder="请输入支付商户公钥" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="私钥" name="privateKey">
                <Input placeholder="请输入支付商户私钥" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="API地址" name="apiUrl">
                <Input placeholder="请输入API地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="回调地址" name="notifyUrl">
                <Input placeholder="请输入回调地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="重定向地址" name="returnUrl">
                <Input placeholder="请输入重定向地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="webhookId" name="webhookId">
                <Input placeholder="请输入webhookId" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
