import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  addAdvertising,
  advertisingDetails,
  editeAdvertising,
} from 'reduxs/advertising/advertising'
import styles from './index.module.less'
const { Option } = Select
const AddEditeModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess, tyList } = props
  const { adsId } = editeData
  const [loading, setLoading] = useState(false)
  const [codeRequre, setCodeRequre] = useState(true)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    if (!adsId) {
      return
    }
    const res = await advertisingDetails(adsId)
    if (res.result === 200) {
      const { data } = res
      setDefaultData({ putinVo: data.putinVo })
      setCodeRequre(data.sysn ? false : true)
      data.sysn = data.sysn ? true : false
      data.sube = data.sube === 1 ? true : false
      console.log(data)
      form.setFieldsValue(data)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const list = tyList[0].data
    const findData = list.find(item => item.questionInfoId === values.infoId)
    values.claszId = findData.claszId
    values.sysn = values.sysn ? 1 : 0
    values.sube = values.sube ? 1 : 0
    if (adsId) {
      editeList(values)
      return
    }

    addList(values)
  }
  // 编辑
  const editeList = async values => {
    values.adsId = adsId
    const res = await editeAdvertising(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改题目成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addAdvertising(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增题目成功~')
      handSuccess()
    }
  }
  const getOptions = (num, id, name) => {
    if (!tyList) {
      return []
    }
    const res = tyList[num] || {}
    const { data } = res
    return data
      ? data.map((item, index) => (
          <Option key={index} value={item[id]}>
            {item[name]}
          </Option>
        ))
      : []
  }
  // 监听系统编号变化
  const handBox = e => {
    const { checked } = e.target
    setCodeRequre(!checked)
  }
  // 监听渠道账户的选择
  const handChange = e => {
    if (!tyList) {
      return
    }
    const res = tyList[2] || {}
    const data = res.data || []
    const findData = data.find(item => item.channelAccountId === e)
    console.log(findData)
    setDefaultData(findData || {})
  }
  return (
    <Modal
      width={650}
      title={!adsId ? '新增投放' : '编辑投放'}
      open={editeData.type ? true : false}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true, sube: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="title"
                required
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="编号" required key={codeRequre}>
                <Input.Group compact>
                  <Form.Item
                    name="code"
                    required={codeRequre ? true : false}
                    noStyle
                    rules={[
                      {
                        required: codeRequre,
                        message: '请输入编号',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入编号"
                      style={{
                        width: '50%',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sysn"
                    className={styles.boxItem}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handBox}>系统编号</Checkbox>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="测评题"
                name="infoId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择题库',
                  },
                ]}
              >
                <Select placeholder="请选择题库">
                  {getOptions(0, 'questionInfoId', 'title')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="邮箱"
                name="sube"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择邮箱',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="模式"
                name="profitType"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择模式',
                  },
                ]}
              >
                <Select placeholder="请选择模式">
                  {getOptions(1, 'val', 'name')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="渠道账户"
                name="channelAccountId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择渠道账户',
                  },
                ]}
              >
                <Select placeholder="请选择渠道账户" onChange={handChange}>
                  {getOptions(2, 'channelAccountId', 'name')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="业务域名"
                name="domainId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择业务域名',
                  },
                ]}
              >
                <Select placeholder="请选择业务域名">
                  {getOptions(3, 'domainId', 'domain')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="投放方式">
                {defaultData?.putinVo?.name}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddEditeModal
