import {
  Button,
  Form,
  Input,
  Menu,
  Select,
  Space,
  Spin,
  Table,
  message,
} from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getBenefitList, removeBenefit } from 'reduxs/advertising/benefit'
import { getBenefitTypeList } from 'reduxs/advertising/benefitType'
import { getClaszList } from 'reduxs/advertising/clasz'
import AddModal from '../Compontents/AddModal'
import ContentModal from '../Compontents/ContentModal'
import { columns } from './_help'
import styles from './index.module.less'
const { Option } = Select
let options = {}
let defaultOpenKeys = [] // 初始展开的菜单
let defaultSelectedKeys = [] // 初始化选中的菜单
let claszId = null
const EquityMange = () => {
  const [form] = Form.useForm()
  const [editeData, setEditeData] = useState({}) // 编辑一行的数据
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState({}) // 页码相关
  const [pageData, setPageData] = useState([]) // 列表
  const [businessType, setBusinessType] = useState([]) // 业务分类
  const [benefitType, setBenefitType] = useState([]) // 权益类型
  const [giveList, setGiveList] = useState([])
  const [menuItems, setMenuItems] = useState([]) // 侧边栏
  const [key, setKey] = useState(false)
  const [disable, setDisable] = useState(false) // 是否可以编辑新增
  useEffect(() => {
    setDisable(true)
    options = { page: 1, limit: 10 }
    // getList()
    getBtypeList()
    getBenefitType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取列表
  const getList = async () => {
    setLoading(true)
    setDisable(true)
    const res = await getBenefitList({ ...options, claszId: claszId })
    setLoading(false)
    setDisable(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 获取业务分类
  const getBtypeList = async () => {
    const res = await getClaszList({ status: 1, paging: false, tree: true })
    if (res.result === 200) {
      const { data } = res
      const treeData = getTreeData(data)
      if (treeData.length) {
        const oneData = treeData[0]
        const selectKey = oneData.children
          ? oneData.children[0].key
          : oneData.key
        defaultSelectedKeys = selectKey
        defaultOpenKeys = [oneData.key]
        claszId = selectKey
        getList()
      }
      setMenuItems(treeData)
      setBusinessType(data)
      setKey(!key)
    }
  }
  // 处理分类树结构
  const getTreeData = list => {
    list.forEach(item => {
      item.key = `${item.claszId}`
      if (item.children?.length) {
        getTreeData(item.children)
      }
    })
    return list
  }
  // 获取权益类型
  const getBenefitType = async () => {
    const res = await getBenefitTypeList({ paging: false, status: 1 })
    if (res.result === 200) {
      const { data } = res
      const list = (data || []).filter(item => item.benefitType === 'ZSCPT') // 赠送题型
      setGiveList(list)
      setBenefitType(data || [])
    }
  }

  // 删除
  const handRemove = async row => {
    const { benefitId } = row
    const res = await removeBenefit(benefitId)
    if (res.result === 200) {
      message.success('删除成功~')
      getList()
    }
  }
  // 编辑
  const handEdite = row => {
    const data = { ...row, _status: true }
    setEditeData(data)
  }
  // 新增
  const handAdd = () => {
    const data = { _status: true }
    setEditeData(data)
  }
  // 内容
  const handContent = row => {
    const data = { ...row, contnetStatus: true }
    setEditeData(data)
  }
  // 事件合集
  const eventData = {
    handEdite,
    handRemove,
    handContent,
    disabled: disable,
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 搜索
  const onFinish = values => {
    setDisable(true)
    const { claszId } = values
    if (claszId?.length) {
      values.claszId = claszId[claszId.length - 1]
    }
    options = { page: 1, limit: 10, ...values }
    getList()
  }
  // 新增编辑成功
  const handSuccess = () => {
    setEditeData({})
    getList()
  }
  // 点击侧边栏
  const handClick = item => {
    setDisable(true)
    const { key } = item
    options = { page: 1, limit: 10 }
    form.resetFields()
    claszId = key
    getList()
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData._status && (
          <AddModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
            businessType={businessType}
            benefitType={benefitType}
            giveList={giveList}
          />
        )}
        {editeData.contnetStatus && (
          <ContentModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}
        <div class={styles.content}>
          <div className={styles.contentLeft} key={key}>
            <Menu
              mode="inline"
              items={menuItems}
              className={styles.menu}
              defaultOpenKeys={defaultOpenKeys}
              defaultSelectedKeys={defaultSelectedKeys}
              onClick={handClick}
            />
          </div>
          <div className={styles.contentRight}>
            <Form onFinish={onFinish} form={form}>
              <Form.Item name="searchKey" className="formItem">
                <Input placeholder="请搜索" style={{ width: '200px' }} />
              </Form.Item>
              {/* <Form.Item name="claszId" className="formItem">
                <Cascader
                  options={businessType}
                  placeholder="请选择"
                  changeOnSelect={false}
                  style={{ minWidth: '200px' }}
                />
              </Form.Item> */}
              <Form.Item name="benefitTypeId" className="formItem">
                <Select
                  placeholder="请选择"
                  style={{ width: '200px' }}
                  allowClear
                >
                  {benefitType.map((item, index) => (
                    <Option value={item.benefitTypeId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="status" className="formItem">
                <Select
                  placeholder="请选择"
                  style={{ width: '200px' }}
                  allowClear
                >
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              </Form.Item>
              <Form.Item className="formItem">
                <Space>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                  <ButtonGroup
                    onClick={handAdd}
                    btnId={btnApiList.equityAdd}
                    disabled={disable}
                  >
                    新增
                  </ButtonGroup>
                </Space>
              </Form.Item>
            </Form>
            <Table
              dataSource={pageData}
              rowKey={item => item.benefitId} //React规范中的array都必须有key
              columns={columns(eventData)}
              size="small"
              onChange={changePage}
              scroll={{
                x: 1300,
              }}
              pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                total: pages.count || 0,
                showTotal: total => `共${total}条`,
                defaultPageSize: 10,
                pageSize: options.limit,
                defaultCurrent: 1,
                current: options.page,
                // pageSizeOptions: [10, 30, 50],
                position: ['bottomLeft'],
              }}
            />
          </div>
        </div>
      </Spin>
    </div>
  )
}
export default withRouter(EquityMange)
